import DynamicSvgIcon from '@/app/_ui/icons/DynamicSvgIcon';
import clsx from 'clsx';
import { type ReactNode } from 'react';

interface SelectCardProps {
  children?: React.ReactNode;
  mode?: 'default' | 'withHeader';
  isSelected?: boolean;
  disabled?: boolean;
  iconName?: string;
  onClick?: () => void;
  className?: string;
  border?: boolean;
  onHoverShowBorder?: boolean;
  isLoading?: boolean;
  disableBgColor?: boolean;
  headerText?: string | ReactNode;
  description?: string;
}

export default function SelectCard({
  isLoading = false,
  children,
  mode = 'default',
  isSelected,
  disabled = false,
  iconName,
  onClick,
  className,
  border = true,
  onHoverShowBorder = true,
  disableBgColor = false,
  headerText,
  description,
}: SelectCardProps) {
  function handleClick() {
    if (onClick) {
      onClick();
    }
  }

  const outerDivClasses = clsx(
    'mb-2 p-2 relative rounded-md transition duration-100',
    border && 'border-2 border box-border',
    !disableBgColor ? 'bg-gray-900' : 'bg-black',
    {
      'border-white border-opacity-15': !isSelected && !disableBgColor,
      'hover:border-opacity-40 cursor-pointer':
        onHoverShowBorder && !isSelected && !disabled,
      'bg-purple-800/40 border-purple-800': isSelected,
      'hover:cursor-default hover:border-[#110F14]': isLoading,
    },
    className,
  );

  function renderCardContent() {
    switch (mode) {
      case 'withHeader':
        return (
          <div className="flex h-full grow flex-col justify-between">
            <div className="flex flex-row justify-between">
              <div className="flex items-center">
                {iconName && (
                  <DynamicSvgIcon
                    iconName={iconName}
                    height={26}
                    width={26}
                    className="rounded-none"
                  />
                )}
                {headerText && <div className="text-lg">{headerText}</div>}
              </div>
              <div>
                {!disabled && (
                  <>
                    <DynamicSvgIcon
                      iconName="white-check"
                      height={26}
                      width={26}
                      className={clsx('p-0', {
                        visible: isSelected,
                        hidden: !isSelected,
                      })}
                    />
                    <DynamicSvgIcon
                      iconName="grey-cirlce-icon"
                      height={26}
                      width={26}
                      className={clsx('p-0', {
                        visible: !isSelected,
                        hidden: isSelected,
                      })}
                    />
                  </>
                )}
              </div>
            </div>

            {description && (
              <div className="mt-2 w-4/5 pr-4 text-sm">
                <p>{description}</p>
              </div>
            )}
            {children && <div>{children}</div>}
          </div>
        );
      default:
        return children;
    }
  }

  return (
    <div className={outerDivClasses} onClick={handleClick}>
      {disabled && (
        <div className="absolute inset-0 z-10 cursor-not-allowed bg-gray-500 bg-opacity-60"></div>
      )}
      {renderCardContent()}
    </div>
  );
}
