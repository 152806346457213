'use client';
import useRoutePath from '@/app/_hooks/useRoutePath';
import { PostHogTracking } from '@/app/_lib/posthog';
import AppButton from '@/app/_ui/buttons/AppButton';
import SelectCard from '@/app/_ui/cards/SelectCard';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

export default function GetStarted() {
  const { currentPathname, previousPathname } = useRoutePath();
  const [selectedCard, setSelectedCard] = useState<'single' | 'mega'>('single');

  const router = useRouter();

  useEffect(() => {
    if (currentPathname) {
      PostHogTracking.getInstance().pageView(currentPathname, previousPathname);
    }
  }, [currentPathname]);

  const handleClick = () => {
    if (selectedCard === 'mega') {
      router.push('dashboard/clusters');
    } else {
      router.push('dashboard/create-cluster');
    }
  };

  const handleSelectCard = (card: 'single' | 'mega') => {
    setSelectedCard(card);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-[700px] sm:w-2/3 md:w-1/2 lg:w-1/3 p-4 mx-auto">
      <div className="flex w-full flex-col items-center p-4 text-center text-white">
        <div>
          <h1 className="my-2 text-xl sm:text-2xl">GPUs at your fingertips</h1>
          <p className="text-sm text-white text-opacity-60 sm:text-base">
            Choose your favorite gpu clouds and always find the most affordable
            compute on the market.
          </p>
        </div>
      </div>
      <div className="mt-4 flex w-full flex-col space-y-2">
        <SelectCard
          mode="withHeader"
          iconName="cube-icon"
          headerText="Single Instance"
          onClick={() => handleSelectCard('single')}
          isSelected={selectedCard === 'single'}
        >
          <div className="mt-1 text-xs font-light text-white text-opacity-60 sm:text-sm">
            Deploy up to 8 GPUs at one single location for the best price
          </div>
        </SelectCard>
        <SelectCard
          mode="withHeader"
          iconName="globe-icon"
          headerText={
            <div className="flex items-center justify-center">
              Megacluster
              <div className="ml-2 rounded-lg bg-[#61577C] px-1 py-1 text-xs">
                Coming Soon
              </div>
            </div>
          }
          onClick={() => handleSelectCard('mega')}
          isSelected={selectedCard === 'mega'}
        >
          <div className="mt-1 text-xs font-light text-white text-opacity-60 sm:text-sm">
            Deploy a multi-node mega-cluster of 8-512 GPUs
          </div>
        </SelectCard>
        <div className="flex flex-col items-center p-2">
          <div className="mt-4 w-full">
            <AppButton
              fullWidth
              text="Continue"
              onClick={handleClick}
              className="hover:bg-[#9946FC]"
            />
          </div>
          <div className="mt-2 px-2 text-center text-xs text-slate-700 sm:px-10">
            <p>
              By proceeding you acknowledge that you have read, understood and
              agree to our
              <Link
                href="https://www.primeintellect.ai/terms-of-service"
                className="text-slate-600 hover:text-slate-400 hover:underline"
                target="_blank"
              >
                &nbsp;Terms and Conditions&nbsp;
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
